import { render, staticRenderFns } from "./DataProcessorEventsDataForm.vue?vue&type=template&id=90ee8e06&scoped=true"
import script from "./DataProcessorEventsDataForm.vue?vue&type=script&lang=js"
export * from "./DataProcessorEventsDataForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90ee8e06",
  null
  
)

export default component.exports