<template>
  <div>
    <v-select
      id="vSelectBotJob"
      v-model="selected_jobs"
      :multiple="multiple"
      :placeholder="placeholder"
      :options="all_jobs"
      :class="validator && selected_jobs.position ? '': 'danger-select' "
      label="position"
      @search="jobSearch"
    />

  </div>
</template>

<script>

import { debounce } from 'lodash'
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    placeholder: { type: String, default: 'Select the user to create the application for' },
    stateErrors: { type: Array, default: null },
    multiple: { type: Boolean, default: false },
    validator: { type: Boolean, default: false },
    clearSelect: { type: Boolean, default: false },
  },
  data() {
    return {
      all_jobs: [],
      selected_jobs: {},
    }
  },
  watch: {
    selected_jobs(val) {
      this.$emit('job-selected', val)
    },
    clearSelect(val) {
      if (val) {
        this.selected_jobs = this.multiple ? [] : {}
      }
    },
  },
  mounted() {
    this.selected_jobs = this.multiple ? [] : {}
  },

  methods: {
    jobSearch(search, loading) {
      if (search.length > 0) {
        loading(true)
        this.searchForJob(loading, search, this)
      }
    },
    searchForJob: debounce((loading, search, vm) => {
      vm.getJobs(search, loading)
    }, 1000),

    getJobs(query, loading = null) {
      this.all_jobs = []
      this.$http
        .get(`/api/bot-job-search/${this.$route.name}/${query}`)
        .then(response => {
          this.all_jobs = []
          const record = response.data

          if (record.success) {
            record.data.map(data => {
              const item = {
                id: data.id,
                position: data.position,
                questions: data.questions,
              }
              this.all_jobs.push(item)
            })
          }
          if (loading) {
            loading(false)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
  },
}
</script>
