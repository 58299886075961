<template>
  <div class="w-100">
    <EventForm
      :wizard-ref="wizard_ref"
			@submitted="$emit('submitted', true)"
    />
  </div>
</template>

<script>

import EventForm from '@/components/jobs/EventForm.vue'

export default {
  components: {
    EventForm
  },

  data() {
    return {
      wizard_ref: null,
    }
  },

  created() {

  },

  methods: {

  },
}
</script>

<style lang="scss" scoped>

</style>