var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","hide-footer":"","title":"Add Record"},model:{value:(_vm.record_popup),callback:function ($$v) {_vm.record_popup=$$v},expression:"record_popup"}},[_c('div',{staticClass:"vx-row"},_vm._l((_vm.formDetails),function(value){return _c('div',{key:value.label,staticClass:"vx-col w-1/2 mb-6"},[(value.label !== '_id')?_c('div',{},[(value.label === 'Userid')?_c('b-form-input',{staticClass:"w-full",attrs:{"id":value.label,"required":"","type":"number","label":value.label,"value":value.value},on:{"change":_vm.updateInput}}):(value.label === 'Phone Number')?_c('b-form-input',{staticClass:"w-full",attrs:{"id":value.label,"type":"number","label":value.label,"value":value.value},on:{"change":_vm.updateInput}}):(value.label === 'Email')?_c('b-form-input',{staticClass:"w-full",attrs:{"id":value.label,"type":"email","label":value.label,"value":value.value},on:{"change":_vm.updateInput}}):(
            [
              'Citizenship',
              'Gender',
              'Employment Status',
              'Highest Qualification' ].includes(value.label)
          )?_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"b-form-input--label"},[_vm._v(_vm._s(value.label))]),_c('v-select',{staticClass:"w-full",attrs:{"danger-text":"This field is required","val-icon-danger":"icon-x","val-icon-pack":"feather","options":_vm.select_dicts[value.label],"label":"value","reduce":function (value) { return value.value; }},model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}})],1):(
            [
              'Preferred Industry',
              'Preferred Job Type',
              'Preferred Location' ].includes(value.label)
          )?_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"b-form-input--label"},[_vm._v(_vm._s(value.label))]),_c('v-select',{staticClass:"w-full",attrs:{"multiple":"","options":_vm.select_dicts[value.label],"label":"value","danger-text":"Please select your citizenship(required)","val-icon-danger":"icon-x","val-icon-pack":"feather","reduce":function (value) { return value.value; }},model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}})],1):_c('b-form-input',{staticClass:"w-full",attrs:{"id":value.label,"label":value.label,"value":value.value},on:{"change":_vm.updateInput}})],1):_vm._e()])}),0),_c('div',{staticClass:"vx-row w-full"},[_c('div',{staticClass:"vx-col mx-auto w-1/2"},[_c('b-button',{staticClass:"w-full mr-3 mb-2",attrs:{"variant":"success"},on:{"click":_vm.submitData}},[_vm._v("Add Record")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }