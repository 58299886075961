<template>
  <div class="vx-col w-full">
    <!-- FIELD: fullname -->
    <b-row class="mx-1 my-2">
      <b-form-input
        v-if="editSection == 'edit'"
        v-model="editData.fullname"
        :state="!checkFullName(editData.fullname)[0]"
        class="w-full"
        placeholder="Full Name"
      />
      <b-form-row
        v-else
        class="w-100 mb-2"
      >
        <b-col class="">
          <b-form-input
            v-model="editData.fullname"
            class="w-100"
            :state="!checkFullName(editData.fullname)[0]"
            danger-text="Please enter a valid full name"
            placeholder="Full Name"
          />
          <b-form-invalid-feedback
            tooltip
          >
            {{ checkFullName(editData.fullname)[1] }}
          </b-form-invalid-feedback>
        </b-col>

      </b-form-row>
    </b-row>

    <!-- FIELD: email -->
    <b-row class="mx-1 my-2">
      <b-form-input
        v-if="editSection == 'edit'"
        v-model="editData.email_address"
        disabled
        class="w-full"
        placeholder="Email Address"
      />
      <b-form-row
        v-else
        class="w-100 mb-2"
      >
        <b-col class="">
          <b-form-input
            v-model="editData.email_address"
            class="w-100"
            :state="!email_check"
            danger-text="Please enter a valid email address"
            placeholder="Email Address"
          />
          <b-form-invalid-feedback
            tooltip
          >
            Please enter a valid email address.
          </b-form-invalid-feedback>
        </b-col>

      </b-form-row>
    </b-row>

    <!-- FIELD: phone_number -->
    <b-row class="mx-1 my-2">
      <b-form-input
        v-if="editSection == 'edit'"
        v-model="editData.phone_number"
        :state="!checkPhoneNumber(editData.phone_number)[0]"
        disabled
        class="w-full"
        placeholder="Phone Number"
        type="number"
        maxlength="8"
      />

      <b-form-row
        v-else
        class="w-100 mb-2"
      >
        <b-col class="">
          <b-form-input
            v-model="editData.phone_number"
            class="w-100"
            :state="!checkPhoneNumber(editData.phone_number)[0]"
            danger-text="Please enter a phone number"
            placeholder="Phone Number"
            type="number"
            :formatter="$limitPhoneNumber"
          />
          <b-form-invalid-feedback
            tooltip
          >
            {{ checkPhoneNumber(editData.phone_number)[1] }}
          </b-form-invalid-feedback>
        </b-col>
      </b-form-row>
    </b-row>

    <!-- FIELD: team -->
    <div
      class="mx-1 my-2"
    >
      <b-form-input
        v-if="editSection == 'edit'"
        v-model="editData.team"
        class="w-full"
        placeholder="Team / Department"
      />
      <b-form-row
        v-else
        class="w-100 mb-2"
      >
        <b-col class="">
          <b-form-input
            v-model="editData.team"
            class="w-100"
            danger-text="Please enter a valid team or department"
            placeholder="Team / Department"
          />
        </b-col>
      </b-form-row>
    </div>

    <b-row
      v-if="
        $store.state.auth.ActiveUser.account_type === this.$AccountTypes.EDUCATION_INSTITUTION
      "
      class="mx-1 my-2"
    >
      <b-form-input
        v-if="
          $store.state.auth.ActiveUser.company_id.includes('persolkelly')
        "
        v-model="editData.recruiter_number"
        class="w-full"
        placeholder="Recruiter Licence Number"
      />
      <b-form-input
        v-else
        v-model="editData.recruiter_number"
        class="w-full"
        placeholder="Recruiter Registration Number"
      />
    </b-row>

    <b-row
      v-if="editData.company_id ? editData.company_id.includes('persolkelly') : false"
      class="mx-1 my-2"
    >
      <v-select
        v-model="editData.organization"
        :placeholder="'Choose an organization'"
        class="selectExample w-100 cursor-pointer"
        :options="employers"
        label="value"
      />
    </b-row>

    <b-row
      v-if="
        editData.company_id
          ? $store.state.auth.ActiveUser.account_type === this.$UserGroups.AGENCY && (editData.company_id !== 'e2i' && editData.company_id !== 'EMPLOYMENT AND EMPLOYABILITY INSTITUTE PTE. LTD.')
          : false"
      class="mx-1"
    >
      <b-form-input
        v-model="editData.agency_number"
        class="w-full"
        placeholder="EA License Number"
      />
    </b-row>

    <b-row
      v-if="editData.company_id ? editData.company_id.includes('persolkelly') : false"
      class="mx-1 my-1"
    >
      <b-form-checkbox
        v-model="editData.view_all"
        class="w-full"
      >Allow view all data</b-form-checkbox>
    </b-row>

    <b-row
      v-if="editData.company_id ? editData.company_id.includes('persolkelly') : false"
      class="mx-1 my-1"
    >
      <b-form-checkbox
        v-model="editData.whitelist"
        class="w-full"
      >Whitelist user for telegram</b-form-checkbox>
    </b-row>

    <b-row class="mx-1 mb-2 mt-1">
      <b-form-checkbox
        v-model="editData.two_factor_active"
        class="w-full"
      >Turn on two-factor authentication</b-form-checkbox>
    </b-row>

    <hr>

    <b-row class="mx-1">
      <b-col
        v-if="editSection !== 'edit'"
        cols="12"
        class="mx-1 my-1"
      >
        <b-form-checkbox
          v-model="editData.send_through_system"
          class="w-full"
        >Send login details through HeadHunters HQ system</b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="w-100 mb-1"
      >
        <b-button
          variant="warning"
          class="w-100 "
          @click="generatePass"
        >
          Generate new password
        </b-button>
      </b-col>

      <b-col>
        <b-alert
          v-if="!editData.send_through_system && show_pass"
          class="mt-1 mx-1"
          variant="warning"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="InfoIcon"
            />
            <span class="ml-25">Please copy the new generated password because it wont be available
              once you close this screen.</span>
          </div>

        </b-alert>
      </b-col>
      <b-col
        cols="12"
        class="w-100 mb-1"
      >
        <b-form-input
          v-model="generated_pass"
          :class="`w-full ${!editData.send_through_system && show_pass ? '' : 'd-none' }`"
          @click="copyTextToClipboard()"
        />
      </b-col>
    </b-row>

    <hr>
    <!-- FIELD: role -->
    <b-row class="mx-1 mb-1">
      <h6>Choose the role for this user</h6>
      <b-form-row
        class="w-100"
      >
        <b-col class="">
          <v-select
            id="role"
            ref="role"
            v-model="selected_role_id"
            :placeholder="
              editData.role !== undefined
                ? editData.role
                : 'Pick a role or assign permissions to this user'
            "
            class="selectExample w-full"
            :options="roleOptions"
            :reduce="role => role.value"
            label="text"
            @input="repopulatePermissions"
          />

          <b-form-invalid-feedback tooltip>
            Please select a valid role.
          </b-form-invalid-feedback>
        </b-col>
      </b-form-row>
    </b-row>

    <permissions-card
      v-if="renderPermissionsCard"
      :user-permissions="editData.permissions"
      @change="handlePermissions"
    />

    <hr>
    <div v-if="$route.name != 'candidates-data'" class="mx-1">
      <div class="">
        <b-button
          class="mr-3 mb-2"
          variant="primary"
          :disabled="checkFullName(edit_data.fullname)[0] || checkPhoneNumber(edit_data.phone_number)[0] || email_check"
          @click="saveChanges"
        >{{
          editSection == 'edit' ? 'Save Changes' : 'Add User'
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import PermissionsCard from './PermissionsCard.vue'

export default {
  components: {
    vSelect,
    PermissionsCard,
  },

  props: {
    editSection: {
      type: String,
      default: 'add',
    },
    initialEditData: {
      type: Object,
      default: () => {},
    }
  },

  data() {
    return {
      editData: {

      },
      selected_role_id: '',
      renderPermissionsCard: true,
      generated_pass: '',

      email_check: true,
      section: {},
      perm: {},

      rolesData: [],
      roleOptions: [],
    }
  },

  watch: {
    'editData.email_address': function (val, oldVal) {
      if (val.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        this.email_check = false
      } else {
        this.email_check = true
      }
    },
  },

  created() {
    this.editData = this.initialEditData

    this.generatePass()

    this.get_roles()
  },

  methods: {
    get_roles() {
      this.$http
        .get('/api/roles')
        .then(response => {
          const { roles } = response.data
          this.rolesData = roles

          this.roleOptions = [
            {
              text: 'Custom',
              value: 'custom',
            },
          ]
          roles.forEach(role => {
            this.roleOptions.push({
              text: role.name,
              value: role.id,
            })
          })
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Operation Failed', 'Could not retrieve the Role list')
        })
    },

    saveChanges() {
      const approved_perms = Object.keys(
        Object.fromEntries(
          Object.entries(this.perm).filter(([key, value]) => value === true),
        ),
      )
      const approved_sections = Object.keys(
        Object.fromEntries(
          Object.entries(this.section).filter(([key, value]) => value === true),
        ),
      )

      // validate
      const validationMessage = this.validateUserFormData()
      if (this.$isEmpty(validationMessage)) {
        if (this.editSection === 'edit') {
          this.updateUsersData(
            approved_perms,
            approved_sections,
            this.generated_pass,
          )
        } else {
          this.createUserAccount(approved_perms, approved_sections)
        }
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Incomplete Form',
              icon: 'AlertCircleIcon',
              text: validationMessage,
              variant: '',
              bodyClass: 'text-break',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    validateUserFormData() {
      let message = ''

      if (this.$isEmpty(this.editData.fullname)) {
        message += 'A valid Full Name is required\n'
      }

      if (this.email_check) {
        message += 'A valid Email is required\n'
      }
      if (this.$isEmpty(this.editData.phone_number)) {
        message += 'A valid Phone Number is required\n'
      }

      // if (this.$isEmpty(this.editData.team)) {
      //   message += 'A valid Team Name is required\n'
      // }

      if (this.$isEmpty(this.selected_role_id)) {
        message += 'Please select a Role\n'
      }

      return message
    },
    createUserAccount(perms, sections) {
      let { company_id, account_type } = this.$store.state.auth.ActiveUser
      let user_group = []
      let hiring_type = 'recruitment agency'

      if (company_id == 'e2i') {
        user_group = ['admin', 'agency']
      } else if (this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY) {
        user_group = ['admin', 'agency']
      } else if (
        this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.EDUCATION_INSTITUTION
      ) {
        user_group = ['admin', 'education_institution']
        hiring_type = 'education_institution'
      } else {
        user_group = ['admin', 'employer']
      }

      const data = {
        fullname: this.editData.fullname,
        email_address: this.editData.email_address.toLowerCase(),
        phone_number: this.editData.phone_number,
        account_type,
        team: this.editData.team,
        password: this.generated_pass,
        location: 'global',
        auto_confirm: true,
        company_id,
        hiring: hiring_type,
        recruiter_number: this.editData.recruiter_number,
        agency_number: this.editData.agency_number,
        whitelist: this.editData.whitelist,
        view_all: this.editData.view_all,
        send_through_system: this.editData.send_through_system,
        role: this.editData.role,
        role_id: this.editData.role_id,
        permissions: this.editData.permissions,
      }

      if (this.editData.organization !== undefined) {
        if (this.editData.organization.length > 3) {
          data.organization = this.editData.organization.split('#')[0]
        }
      }
      if (user_group) {
        data.user_group = user_group
      }

      if (company_id) {
        data.company_id = company_id
      }

      if (this.editData.team) {
        // alert(this.editData.team)
        data.team = this.editData.team
      }
      if (this.editData.fullname) {
        data.fullname = this.editData.fullname
      }

      if (this.editData.recruiter_number) {
        data.recruiter_number = this.editData.recruiter_number
      }

      if (this.editData.agency_number) {
        data.agency_number = this.editData.agency_number
      }

      this.$http
        .post('/api/auth/register', {
          user: data,
        })
        .then(response => {
          const output = response.data
          // this.$set(this, 'editData', { ...output, ...this.editData})
          this.editData = { ...output, ...this.editData}

          if (output) {
            // this.updateUsersData(
            //   perms,
            //   sections,
            //   '',
            //   (user_group = user_group),
            //   (company_id = company_id),
            // )
            this.edit_popup = false
            this.$toastSuccess('Account Creation Update', `${output.email_address} account has been created successfully.`)
          } else {
            this.$toastDanger('Account Creation Failed', 'Please try again later.')
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Account Creation Failed', error.response.data.errors.body[0])
            .then(() => {
              this.isLoading = false
            })

        })
    },
    updateUsersData(
      approved_perms,
      approved_sections,
      password,
      user_group = '',
      company_id = '',
    ) {
      const { token } = this.$store.state.auth.ActiveUser
      const email = this.editData.email_address.toLowerCase()
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true

      const data = {
        id: this.editData.id,
        perm_rights: approved_perms,
        perm_sections: approved_sections,
        two_factor_active: this.editData.two_factor_active,
        company_id: this.$store.state.auth.ActiveUser.company_id,
        whitelist: this.editData.whitelist,
        view_all: this.editData.view_all,
      }

      if (this.editData.organization !== undefined) {
        if (this.editData.organization.length > 3) {
          data.organization = this.editData.organization.split('#')[0]
          // this.select_company.split("#")[1],
        }
      }

      if (user_group) {
        data.user_group = user_group
      }

      if (company_id) {
        data.company_id = company_id
      }

      if (password) {
        data.password = password
      }

      if (this.editData.team) {
        data.team = this.editData.team
      }

      if (this.editData.fullname) {
        data.fullname = this.editData.fullname
      }

      if (this.editData.recruiter_number) {
        data.recruiter_number = this.editData.recruiter_number
      }

      if (this.editData.agency_number) {
        data.agency_number = this.editData.agency_number
      }

      this.$http
        // .put(`/api/company-user/${id}`, {
        .put(`/api/company-user`, {
          user: data,
        })
        .then(response => {
          const output = response.data
          if (output.success) {
            this.edit_popup = false
            this.$toastSuccess('Update Successful', 'Your changes have been saved.')
          } else {
            this.$toastDanger('Update Failed', 'You changes have failed')
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$$handleErrorResponse(error, 'Operation Failed', 'Could not retrieve the company user list. Please try again later.')

          this.isLoading = false
        })
    },
    deleteSelected() {
      this.get_company_users()
    },
    copyTextToClipboard() {
      const text = this.generated_pass
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )

      this.$toastSuccess('Copy Operation', 'Text copied successfully')
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16))
    },
    generatePass() {
      const uniq = this.uuidv4()
      this.generated_pass = uniq
      this.show_pass = true
    },

    checkFullName(fullname) {
      let state = false
      let message = ''
      const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/

      if (!(fullname.length > 2)) {
        state = true
        message = 'A valid fullname is required (only letters allowed)'
      }

      if (format.test(fullname)) {
        state = true
        message = 'A valid fullname is required (only letters allowed)'
      }

      return [state, message]
    },
    checkPhoneNumber(value) {
      let state = false
      let message = ''

      if (value == null || value.length < 8) {
        state = true
        message = 'phone number must be at least 8 digit'
      } else {
        state = false
        message = ''
      }

      return [state, message]
    },

    // permissions
    repopulatePermissions() {
      this.$http
        .get(`/api/roles/${this.selected_role_id}`)
        .then(response => {
          const role = response.data

          this.$delete(this.editData, 'permissions')
          this.$set(this.editData, 'permissions', role.permissions)
          this.$set(this.editData, 'role', role.name)
          this.$set(this.editData, 'role_id', role.id)

          // to force permissioncard to display the new permissions
          this.renderPermissionsCard = false
          setTimeout(() => {
            this.renderPermissionsCard = true
          }, 100)
        })
        .catch(() => {
          this.$toastDanger()
        })
    },
    handlePermissions(permissions) {
      this.editData.permissions = permissions
      this.selected_role_id = 'custom'
      this.$set(this.editData, 'role', 'custom')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
